<template>
  <div>
    <button @click="clickNavigateToLive">跳转直播页</button>
    <button @click="clickNavigateToWebView">跳转webView</button>
  </div>
</template>

<script>
  export default {
    methods: {
      clickNavigateToLive () {
        wx.miniProgram.navigateTo({ url: `/pages/i-live/index` })
      },
      clickNavigateToWebView () {
        const pathUrl = `https://large-platform-mobile.medical-force.cn/#/agreement/ys`;
        wx.miniProgram.navigateTo({ url: `/pages/other/web-view/index?url=${pathUrl}` })
      }
    }
  }
</script>